@import 'variables';
@import 'mixins';

.full-width {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

.divider {
  width: 100%;
  margin: 16px 0 !important;
}
