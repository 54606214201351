@import 'src/styles/variables';
@import 'src/styles/mixins';

.svg-btn-wrapper {
  display: flex;
  padding: 4px;
  flex-direction: row;
  cursor: pointer;
}

.selected {
  color: $lightBlue;
}

.item-icon {
  min-width: 32px !important;
}

.outlined {
  border: 2px solid $gray;
}
