@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/common';

.info-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 16px;

  .text-icon {
    margin: 0 8px;
    width: 20px;
    height: 20px;
  }

  .svg-btn-icon {
    margin: 0 8px;
    padding: 0;
  }

  .row-with-icons {
    display: flex;
    flex-direction: row;
    align-content: center;

    .text-icon,
    svg {
      align-self: center;
    }
  }

  .ten-mm-wrapper {
    display: flex;
    flex-direction: row;
    height: 10mm;
    align-content: center;
    margin: 4px;

    .ten-mm {
      display: flex;
      width: 10mm;
      height: 10mm;
      background: $gray;
      margin-right: 16px;
    }
    .ten-mm-text {
      align-self: center;
    }
  }

  .credit-card-wrapper {
    width: 85.6mm;
    //height: 53.98mm;
    height: 85.6mm;
  }

  .page-img-1,
  .page-img-c {
    width: 600px;
    height: 520px;
    background-repeat: no-repeat;
    background-color: $white;
    background-size: contain;
  }
  .page-img-1 {
    background-image: url('/assets/images/check_page_1.png');
  }
  .page-img-c {
    background-image: url('/assets/images/check_page_c.png');
  }
}
