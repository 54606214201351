@import '../../../styles/variables';
@import '../../../styles/mixins';

.confirm-wrapper {
  padding: 16px;
  flex-direction: column;
  background: $white;
  box-shadow: 2px 0px 10px $grayLight;
  border-radius: 8px;
  //background: lightblue;
  //max-width: 70%;

  .confirm-render-wrapper {
    width: 100%;
    max-height: 300px;
    overflow: scroll;
  }
}
