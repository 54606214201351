@import 'src/styles/variables';

.full-screen-wrapper {
  position: relative;
  background: $white;

  .full-screen-btn {
    position: absolute;
    width: 48px;
    bottom: 0;
    right: 0;
  }
}
