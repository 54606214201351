@import 'src/styles/variables';
@import 'src/styles/common';

.statistics-table-wrapper {
  display: flex;
  flex-direction: row;
  border: 2px solid $grayLight;

  .statistics-table-section {
    padding: 8px;
    width: 50%;

    .label-value-wrapper {
      padding: 4px;
    }

    .error {
      color: red;
      border-bottom: 1px solid $red;
    }
  }
}
