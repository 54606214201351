@import 'src/styles/variables';
@import 'src/styles/mixins';

.home-wrapper {
  position: relative;
  width: 100%;
  padding: 16px;

  .home-wrapper-image {
    position: absolute;
    width: 100%;
    height: 768px;
    background-image: url('/assets/images/bg-check-vision.jpeg');
    background-repeat: no-repeat;
    background-color: $white;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
  }

  .home-link-wrapper {
    text-decoration: none;
    color: $lightBlue;
  }

  .home-start-btn-wrapper {
    text-decoration: none;
    max-width: 200px;
    width: 200px;

    .home-start-btn {
      font-weight: bold;
      color: $white;
      padding: 4px;
      max-width: 200px;
      text-align: center;
      border-radius: 8px;
      background: $lightBlue;
    }
  }


  .home-imgs-wrapper {
    display: flex;
    flex-direction: row;
    .page-img-1,
    .page-img-c {
      width: 300px;
      height: 260px;
      background-repeat: no-repeat;
      background-color: $white;
      background-size: contain;
      margin: 4px;
      border: 2px solid $grayLight;
    }
    .page-img-1 {
      background-image: url('/assets/images/check_page_1.png');
    }
    .page-img-c {
      background-image: url('/assets/images/check_page_c.png');
    }
  }

}
