//colors
$white: #ffffff;
$grayLight: #cccccc;
$gray: #999999;
$darkGray: #444444;
$lightBlack: #151515;
$black: #000000;
$red: #ff0020;
$lightBlue: #3366bb;
$blue: #2255aa;

//size
$maxFormWidth: 1000px;
$maxFormFieldWidth: 300px;
$maxRegionRowWidth: 700px;
$minRegionRowWidth: 120px;
$regionRowHeight: 48px;
$modalWidth: 700px;

$drawerCloseWidth: 57px;
$drawerOpenWidth: 160px;
$drawerHeaderHeight: 64px;

$sideBtnWidth: 60px;

$statisticsChartHeight: 350px;
