@import 'src/styles/variables';
@import 'src/styles/mixins';

.drawer-wrapper {
  display: flex;
  padding-top: $drawerHeaderHeight;
  height: 100%;

  .app-bar {
    z-index: 5;
    transition: 0.2s;
    height: $drawerHeaderHeight;
    background: $white;
  }
  .drawer-app-bar-shift {
    margin-left: $drawerOpenWidth;
    width: calc(100% - 160px);
    width: -moz-calc(100% - 160px);
    width: -webkit-calc(100% - 160px);
    transition: 0.2s;
  }
  .drawer {
    width: $drawerCloseWidth;
    flex-shrink: 0;
    white-space: nowrap;
  }
  .drawer-open {
    width: $drawerOpenWidth;
    transition: 0.2s;
  }
  .drawer-close {
    width: $drawerCloseWidth;
    transition: 0.2s;
    overflow-x: hidden;
  }
  .drawer-toolbar {
    display: flex;
    align-items: center;
    height: $drawerHeaderHeight;
    padding: 0 8px;
  }
  .drawer-list-item-icon{
    min-width: 40px;
  }
  .drawer-item-label {
    color: $darkGray;
  }
  .drawer-content {
    flex-grow: 1;
  }
  .icon-tab-active {
    fill: $blue;
  }
  .icon-tab {
    fill: $darkGray;
  }
}