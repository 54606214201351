@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/common';

.contacts-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 16px;

  .text-icon {
    margin: 0 8px;
  }

  .row-with-icons {
    display: flex;
    flex-direction: row;
    align-content: center;

    .text-icon,
    svg {
      align-self: center;
    }
  }
}
