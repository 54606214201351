@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal-block-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  bottom: 0;
  right: 0;
  z-index: -10;
  opacity: 0;
  max-height: 0;
  overflow: auto;
  transition: 0s linear 0.3s, opacity 0.3s;
  box-sizing: border-box;
  visibility: hidden;
  background: rgba(230, 230, 230, 0.2);
}
.modal-block-container.show {
  z-index: 1000;
  opacity: 1;
  max-height: 100vh;
  transition: 0s linear 0s, opacity 0.3s;
  visibility: visible;
  overflow: auto;
}

.modal-block {
  position: relative;
  z-index: 3;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 786px;
  box-sizing: border-box;
  overflow: auto;
  box-shadow: 0px 0px 12px 2px rgba(57, 57, 57, 0.4);
  background-color: #cccccc;

  &.modal-black-bg {
    background-color: #cccccc;
  }

  &.modal-white-bg {
    background-color: $white;
    max-width: 1200px;
    min-height: 600px;
    width: 90%;
    overflow: visible;
    padding: 50px 90px;
  }
}

.modal-bg {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.85);
}

.modal-close-icon {
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
  width: 35px;
  height: 35px;

}
.modal-close-icon:hover:before, .modal-close-icon:hover:after {
  background-color: #a8a8a8;
}
.modal-close-icon:before, .modal-close-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  transition: 0.2s;
  background-color: $grayLight;
}

.modal-white-bg .modal-close-icon:before, .modal-white-bg .modal-close-icon:after {
  background-color: $darkGray;
}
.modal-close-icon:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal-close-icon:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.standard-modal-wrapper {
  padding: 20px;
}

.standard-modal {
  overflow: visible;
  max-width: $modalWidth;
}

.standard-modal .modal-close-icon {
  width: 35px;
  height: 35px;
  right: 8px;
  top: 8px;
}

.standard-modal .modal-close-icon:before,
.standard-modal .modal-close-icon:after {
  height: 5px;
  border-radius: 100%;
}

