@import 'src/styles/variables';
@import 'src/styles/mixins';

.app-bar {
  z-index: 5;
  transition: 0.2s;
  height: $drawerHeaderHeight;
  background: $white;

  .drawer-title {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: $drawerHeaderHeight;
  }
  .drawer-menu-button {
    margin-left: 12px;
  }
  .app-bar-right {
    display: flex;
    flex: 1;
    align-self: flex-end;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .app-bar-btn{
      margin-right: 16px;
      cursor: pointer;
    }
    .app-bar-distance-label {
      font-size: 16px;
      color: $darkGray;
      margin-right: 8px;
    }
    .app-bar-distance-value {
      font-size: 16px;
      font-weight: bold;
      color: $darkGray;
      max-width: 80px;
      overflow: hidden;
      margin-right: 16px;
    }
  }
  .hide {
    display: none;
  }
}

.drawer-app-bar-shift {
  margin-left: $drawerOpenWidth;
  width: calc(100% - 160px);
  width: -moz-calc(100% - 160px);
  width: -webkit-calc(100% - 160px);
  transition: 0.2s;
}
