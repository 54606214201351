@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/common';

.check-wrapper {
  display: flex;
  position: relative;
  padding: 16px;
  flex-direction: row;
  background: $white;

  .check-img-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .check-left-side-bar-wrapper {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 32px;
    color: $gray;
    .active-icon {
      svg {
        fill: #61dafb;
      }
    }
    .disabled-icon {
      opacity: 0.5;
    }
  }

  .check-left-side-bar {
    overflow: scroll;
  }

  .check-left-side-bar-content {
    height: calc(80vh - 40px);
  }

  .check-full-screen-btn {
    position: fixed;
    width: 24px;
    bottom: 24px;
    right: 120px;
  }
}
