@import 'src/styles/variables';
@import 'src/styles/mixins';

.label-value-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;

  .value-wrapper {
    margin-left: 4px;
    margin-right: 4px;
  }

  .error {
    color: $red;
  }
}

