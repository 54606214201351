@import 'src/styles/variables';
@import 'src/styles/mixins';

.svg-btn-wrapper {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background: #eee;
    transition: 0.5s;
  }
}

.svb-button-disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
}

.outlined {
  border: 2px solid $gray;
}
