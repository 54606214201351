@import 'src/styles/variables';
@import 'src/styles/mixins';

.e-image-wrapper {
  position: relative;
  background: #000000;
  border: none;

  .e-image-light-line-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    right: -1px;
    height: 20%;
    background: #ffffff;
    border: none;
  }

  .e-image-light-line-2 {
    position: absolute;
    top: 60%;
    left: 20%;
    right: -1px;
    height: 20%;
    background: #ffffff;
    border: none;
  }
}

.c-image-wrapper {
  position: relative;
  background: #000000;
  border-radius: 50%;
  border-width: 0;
  transition: 0.3s; // TODO: check if need

  .c-image-inner-circle {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    background: #ffffff;
    border-radius: 50%;
    border-width: 0;
  }

  .c-image-light-line {
    position: absolute;
    top: 40%;
    right: -1px;
    height: 20%;
    width: 50%;
    background: #ffffff;
    border: none;
  }
}

