@import 'src/styles/variables';

.warning-wrapper {
  display: flex;
  width: 600px;
  height: 600px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
