@import 'src/styles/variables';
@import 'src/styles/mixins';

.statistics-wrapper {
  display: flex;
  position: relative;
  padding: 16px;
  flex-direction: column;
  background: $white;

  .chart-section {
    width: 100%;
    height: $statisticsChartHeight;
    margin: 16px;
  }
  .chart-wrapper {
    width: 1200px;
    height: $statisticsChartHeight;
    background: #eee;
    overflow: scroll;
  }
  canvas {
    height: $statisticsChartHeight !important;
  }
}
